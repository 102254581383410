export function header() {

  const header = document.querySelector('.j-header');
  const header_btn = document.querySelector('.j-header_btn');
  const header_links = document.querySelectorAll('.j-header_link');


  header_btn.addEventListener('click', function(){
    header.classList.toggle('active');
  });

  [...header_links].forEach((link) => {
    link.addEventListener('click', ()=>{
      header.classList.remove('active');
    });
  });
}

// j-headerをクリックするとnavにクラスがつく
