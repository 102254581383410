import Swiper from 'swiper/swiper-bundle';

export function mv() {
    let mySwiper = new Swiper('.j-mvSlider', {
        spaceBetween: 30,
        effect: "fade",
        autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        },
    });

}