import { header } from './modules/header';
import { animation } from './modules/animation';
import { accordion } from './modules/accordion';
import { mv } from './modules/mv';
header();
animation();
accordion();
mv();

document.addEventListener("DOMContentLoaded", function() {
    // 全ての j-dialogue_button を取得
    var buttons = document.querySelectorAll(".j-dialogue_button");

    buttons.forEach(function(button) {
        // 各ボタンにクリックイベントを追加
        button.addEventListener("click", function() {

			// 現在のボタンの親要素（.j-dialogue）を取得
            var dialogue = button.closest(".j-dialogue");
            // 親要素内の j-dialogue_cont を取得
            var cont = dialogue.querySelector(".j-dialogue_cont");

			document.querySelectorAll(".j-dialogue_button").forEach(function(btn) {
                btn.classList.remove("active");
            });

			if (cont.style.display === "none" || cont.style.display === "") {
				// 全ての j-dialogue_cont を非表示にする
				document.querySelectorAll(".j-dialogue_cont").forEach(function(content) {
					content.style.display = "none";
				});

				cont.style.display = "block";
				button.classList.add("active");
			} else {
				// 全ての j-dialogue_cont を非表示にする
				document.querySelectorAll(".j-dialogue_cont").forEach(function(content) {
					cont.style.display = "none";
					button.classList.remove("active");
				});
			}
        });
    });
});
